import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';
// 碎片
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
// 素材
import page3tit from './images/page3tit.png';
import page4tit from './images/newPage4tit.png';
import logo2x from './images/logo@2x.png';
import line from './images/line.png';
import loadImg1 from './images/loadIcon.png';
import loadImg2 from './images/loadImg2.png';
import { handleDownLoad } from './handleDownload';
// 方法
import { Browser } from './server/util';
// 组件
import P2Item from './components/P2Item';
import Acticle from './components/Acticle';
import Picture from './components/Picture';
// png
import indexIMG from './png/index.jpg';
import p2IMG from './png/p2.png';
// gif
import index from './gif/index.gif';
// import p2Gif2 from './gif/p2Gif2.gif';
// import p2Gif3 from './gif/p2Gif3.gif';
import page1Bg from './images/bg.jpg';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    constructor(props) {
        super(props);
        this.state = {
            isStopped: false,
            isPaused: false,
            isIos: true,
            isSafari: false, // safari
            isQQ: false, // QQ
            isUc: false, // Uc
            is360: false, // 360
            isBaidu: false, // baidu
            isQuark: false, // Quark
            isOPPO: false, // OPPO
            isWeChat: false, // weChat
            webview: '', // 浏览器
            videoType: 'gif', // gif/png(弃用video/json)
            currentIndex: 0,
            currentSonIndex: 0,
        };
        this.siteRef = React.createRef();
    }

    computeFrame() {}
    // Canvas
    playCanvas() {
        window.onload = () => {
            const video = document.getElementById('video');
            const c1 = document.getElementById('c1');
            const ctx1 = c1.getContext('2d');
            const c2 = document.getElementById('c2');
            const ctx2 = c2.getContext('2d');

            ctx1.drawImage(video, 100, 100);

            const frame = ctx1.getImageData(0, 0, 100, 100);

            const l = frame.data.length / 4;

            for (let i = 0; i < l; i++) {
                const r = frame.data[i * 4 + 0];
                const g = frame.data[i * 4 + 1];
                const b = frame.data[i * 4 + 2];

                if (g > 100 && r > 100 && b < 43) frame.data[i * 4 + 3] = 0;
            }
            ctx2.putImageData(frame, 0, 0, 100, 100);
        };
    }
    // 轮播
    slideInit() {
        const initSwiper = {
            direction: 'vertical',
            initialSlide: 0,
            prevButton: '.pre-page-btn',
            nextButton: '.next-page-btn',
        };
        const swiperFatherDom = document.getElementsByClassName('swiper-container')[0];
        const swipersonDom = document.getElementsByClassName('swiper-container-son')[0];

        // eslint-disable-next-line no-undef
        const swiperFather = new Swiper('.swiper-container', initSwiper);

        swiperFather.on('slideChange', () => {
            this.setState({ currentIndex: swiperFather.activeIndex });
        });

        // eslint-disable-next-line no-undef
        const swiperSon = new Swiper('.swiper-container-son', {
            loop: true,
            // 分页器
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });

        swiperSon.on('slideChange', () => {
            this.setState({ currentSonIndex: swiperSon.activeIndex });
        });
    }
    // 点击下载
    handleDownload = () => {
        handleDownLoad();
    };

    // 白名单
    setWhiteList = () => {
        const browser = Browser();
        const status = browser.ios;
        const ua = browser.ua;
        const isOppoClient = browser.isOppoClient;

        this.setState({
            isIos: status,
            webview: ua,
        });

        if ((isOppoClient && browser.isQuark) || browser.isUc) {
            this.setState({
                videoType: 'png',
            });

            return;
        }
    };
    setSiteSize = () => {
        const siteDom = this.siteRef.current;
        const siteDomWidth = siteDom.clientWidth;

        const siteDomHeight = (siteDomWidth * 1609) / 804;
        const browser = Browser();
        const ua = browser.ua;

        let htmlFontSize = parseFloat(document.getElementsByTagName('HTML')[0].style.fontSize);
        // 华为30Pro
        const isHUAWEIE30Pro = ua.match('HUAWEIEBG-AN00');

        if (isHUAWEIE30Pro || browser.isQQ) {
            // alert('华为30Pro/QQ浏览器');
            htmlFontSize = 36;
        }
        const resultHeight = siteDomHeight / htmlFontSize / 1;

        siteDom.style.height = `${resultHeight}rem`;
    };

    setClassName = () => {
        const browser = Browser();
        const isSafari = browser.isSafari;
        const isUc = browser.isUc;
        const isBaidu = browser.isBaidu;
        const isQQ = browser.isQQ;
        const isQuark = browser.isQuark;
        const isOPPO = browser.isOPPO;
        const is360 = browser.is360;
        const isWeChat = browser.isWeChat;

        this.setState({
            isSafari,
            isUc,
            isBaidu,
            isQQ,
            isQuark,
            isOPPO,
            is360,
            isWeChat,
        });
    };
    componentDidMount = () => {
        // 白名单
        this.setWhiteList();

        // 轮播
        this.slideInit();

        // 计算site高度
        // this.setSiteSize();

        this.setClassName();

        // 序列帧
        // this.imgSequenceAni();
        // Canvas绘制
        // this.playCanvas();
    };

    render() {
        const {
            videoType, // 视频类型
            isIos,
            webview,
            currentIndex,
            currentSonIndex,
            isSafari,
            isUc,
            isBaidu,
            isQQ,
            isQuark,
            isOPPO,
            is360,
            isWeChat,
        } = this.state;
        const {
            content,
            content: { Coverage, page3, page4, page5, page6 },
        } = this.props;

        return (
            <div className={`${styles.container} swiper-container`}>
                <div
                    className={`${styles.bgSite}`}
                    style={{
                        backgroundImage: currentIndex === 0 ? `url(${page1Bg})` : '',
                    }}
                />
                {/* <div style={{ position: 'fixed', zIndex: '999' }}>
                    <div>机型：{isIos ? 'ios' : 'Android'}</div>
                    <div>UA:{webview}</div>
                    <div>屏幕宽度：{window.innerWidth}</div>
                    <div>屏幕高度：{window.innerHeight}</div>
                    <div>当前页:{currentIndex}</div>
                    <div>当前页:{currentSonIndex}</div>
                    <div>类型：{videoType}</div>
                </div> */}

                <div className="swiper-wrapper">
                    {/* 首页 */}
                    <div className={`${styles.page2} swiper-slide`}>
                        <div className={styles.page2Inner}>
                            {/* png */}
                            {videoType === 'png' ? (
                                <img
                                    className={`${styles.video_player} ${isSafari ? styles.Safari : ''} ${
                                        isUc ? styles.Uc : ''
                                    } ${isBaidu ? styles.Baidu : ''} ${isIos ? styles.Ios : ''} ${
                                        isQQ ? styles.QQ : ''
                                    } ${isQuark ? styles.Quark : ''} ${isOPPO ? styles.OPPO : ''} ${
                                        is360 ? styles.is360 : ''
                                    }`}
                                    src="https://x0.ifengimg.com/ucms/2024_08/E81AB6653FDACB2F09F7ACF4D4590E9CDA636300_size273_w750_h1556.jpg"
                                    alt=""
                                />
                            ) : null}

                            {/* gif */}
                            {videoType === 'gif' ? (
                                <img
                                    className={`${styles.video_player} ${isSafari ? styles.Safari : ''} ${
                                        isUc ? styles.Uc : ''
                                    } ${isBaidu ? styles.Baidu : ''} ${isIos ? styles.Ios : ''} ${
                                        isQQ ? styles.QQ : ''
                                    } ${isQuark ? styles.Quark : ''} ${isOPPO ? styles.OPPO : ''} ${
                                        is360 ? styles.is360 : ''
                                    } ${isWeChat ? styles.WeChat : ''}`}
                                    src="https://x0.ifengimg.com/ucms/2024_08/E81AB6653FDACB2F09F7ACF4D4590E9CDA636300_size273_w750_h1556.jpg"
                                    alt=""
                                />
                            ) : null}

                            <div className={styles.logoWrap}>
                                <img className={styles.logo2x} src={logo2x} />
                                <img className={styles.line} src={line} />
                                <div className={styles.logoContent}>
                                    <div>中华情怀</div>
                                    <div className={styles.circle} />
                                    <div>全球视野</div>
                                    <div className={styles.circle} />
                                    <div>包容开放</div>
                                    <div className={styles.circle} />
                                    <div>进步力量</div>
                                </div>
                            </div>

                            <div className={styles.page2btn} onClick={this.handleDownload}>
                                <div className={styles.loadIcon}>
                                    <div>立即下载</div>
                                    <img src={loadImg1} />
                                </div>
                            </div>
                            <div className={styles.go}>
                                <a href="//i.ifeng.com">
                                    进入网页<span />
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* 地图 */}
                    {/* <div className={`${styles.page3} swiper-slide`}>
                        <img className={styles.tit} src={page3tit} />

                        <Chip title="覆盖率" type="struct" id="260209" groupName="覆盖率" content={Coverage}>
                            <P2Item />
                        </Chip>

                        {videoType === 'png' ? <img className={styles.p2IMG} src={p2IMG} alt="" /> : null}
           
                        {videoType === 'gif' ? <img className={styles.page3Img} src={p2Gif3} alt="" /> : null}

                        <div className={styles.otherbtn} onClick={this.handleDownload}>
                            <div className={styles.loadIcon}>
                                <div>立即下载</div>
                                <img src={loadImg2} />
                            </div>
                        </div>
                    </div> */}

                    {/* 大事件 */}
                    <div className={`swiper-slide ${styles.pageWrap} `}>
                        <div className={`${styles.container} swiper-container-son`} styles={{ background: '#fff' }}>
                            <div className={`swiper-wrapper ${styles.pageOtherWrap} `}>
                                <div className={`${styles.page4} swiper-slide`}>
                                    <Chip title="页面3" type="struct" id="260210" groupName="页面3" content={page3}>
                                        <Acticle />
                                    </Chip>
                                    <Chip
                                        title="页面3图片"
                                        type="struct"
                                        id="260210"
                                        groupName="页面3图片"
                                        content={page3}>
                                        <Picture />
                                    </Chip>
                                </div>
                                <div className={`${styles.page5} swiper-slide`}>
                                    <Chip title="页面4" type="struct" id="260211" groupName="页面4" content={page4}>
                                        <Acticle />
                                    </Chip>
                                    <Chip
                                        title="页面4图片"
                                        type="struct"
                                        id="260211"
                                        groupName="页面4图片"
                                        content={page4}>
                                        <Picture />
                                    </Chip>
                                </div>
                                <div className={`${styles.page6} swiper-slide`}>
                                    <Chip title="页面5" type="struct" id="260212" groupName="页面5" content={page5}>
                                        <Acticle />
                                    </Chip>
                                    <Chip
                                        title="页面5图片"
                                        type="struct"
                                        id="260212"
                                        groupName="页面5图片"
                                        content={page5}>
                                        <Picture />
                                    </Chip>
                                </div>
                                <div className={`${styles.page7} swiper-slide`}>
                                    <Chip title="页面6" type="struct" id="260213" groupName="页面6" content={page6}>
                                        <Acticle />
                                    </Chip>
                                    <Chip
                                        title="页面6图片"
                                        type="struct"
                                        id="260213"
                                        groupName="页面6图片"
                                        content={page6}>
                                        <Picture />
                                    </Chip>
                                </div>
                            </div>

                            <img
                                className={`${styles.tit} ${isSafari ? styles.Safari : ''} ${isUc ? styles.Uc : ''} ${
                                    isBaidu ? styles.Baidu : ''
                                } ${isQQ ? styles.QQ : ''} ${isQuark ? styles.Quark : ''} ${isOPPO ? styles.OPPO : ''}`}
                                src={page4tit}
                            />

                            <div className={styles.otherbtn} onClick={this.handleDownload}>
                                <div className={styles.loadIcon}>
                                    <div>立即下载</div>
                                    <img src={loadImg2} />
                                </div>
                            </div>
                            <div className="swiper-pagination" />
                        </div>
                    </div>
                </div>
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
