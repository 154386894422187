const ua = navigator.userAgent;
const uaMsg = {
    isWhere: reg => {
        return ua.match(reg);
    },
    weixin: ua.match(/MicroMessenger/gi),
    isWeibo: ua.match(/WeiBo/i),
    QQ: ua.match(/QQ\//),
    isAndroid: ua.match(/android/gi),
    isIos: ua.match(/iphone|ipod/gi),
    isIpad: ua.match(/ipad/gi),
    wxosEdition: () => {
        const reg = /([1-9]?[0-9])_([0-9])/gi;
        const iosarr = reg.exec(ua);

        if (iosarr != null && (this.isIos || this.isIpad) && iosarr.length >= 3) {
            return iosarr[1];
        } else {
            return 0;
        }
    },
};

const downloadUrls = {
    ios: 'https://apps.apple.com/cn/app/id395133418',
    android: 'https://statistics.appstore.ifeng.com/index.php/api/godownload?app_id=4&d_ch=8704',
    // tcApp: 'http://a.app.qq.com/o/simple.jsp?pkgname=com.ifeng.news2&ckey=CK1354553159819',
    tcApp: 'http://a.app.qq.com/o/simple.jsp?pkgname=com.ifeng.news2&ckey=CK1452784627905',
};

const handleDownLoad = () => {
    let downloadUrl = '';

    // alert(JSON.stringify(uaMsg));

    if (uaMsg.isIos || uaMsg.isIpad) {
        downloadUrl = downloadUrls.ios;

        if (uaMsg.weixin) {
            downloadUrl = downloadUrls.ios;
        }
    }

    if ((uaMsg.isIos || uaMsg.isIpad) && !uaMsg.weixin) {
        downloadUrl = downloadUrls.ios;
    }

    if (uaMsg.isAndroid) {
        downloadUrl = downloadUrls.android;

        if (uaMsg.weixin) {
            downloadUrl = downloadUrls.tcApp;
        }
    }

    location.href = downloadUrl;
};

export { handleDownLoad };
