import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';
import { handleDownLoad } from '../../handleDownload';
import page4img from '../../images/p4img.png';
import { Browser } from '../../server/util';
class Acticle extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };
    /* 初始化*/
    constructor(props) {
        super(props);
        this.state = {
            isSafari: false, // safari
            isQQ: false, // QQ
            isUc: false, // Uc
            isBaidu: false, // baidu
            isQuark: false, // Quark
            isVivo: false,
            is360: false,
        };
    }

    handleDownload = () => {
        handleDownLoad();
    };

    setClassName = () => {
        const browser = Browser();
        const isSafari = browser.isSafari;
        const isUc = browser.isUc;
        const isBaidu = browser.isBaidu;
        const isQQ = browser.isQQ;
        const isQuark = browser.isQuark;
        const isVivo = browser.isVivo;
        const is360 = browser.is360;

        this.setState({
            isSafari,
            isUc,
            isBaidu,
            isQQ,
            isQuark,
            isVivo,
            is360,
        });
    };
    componentDidMount = () => {
        this.setClassName();
    };

    render() {
        const {
            isSafari, //
            isUc,
            isBaidu,
            isQQ,
            isQuark,
            isVivo,
            is360,
        } = this.state;
        const { content } = this.props;

        return (
            <div
                className={`${styles.contentWrap} ${isSafari ? styles.Safari : ''} ${isUc ? styles.Uc : ''} ${
                    isBaidu ? styles.Baidu : ''
                }  ${isQQ ? styles.QQ : ''} ${isQuark ? styles.Quark : ''} ${isVivo ? styles.Vivo : ''} ${
                    is360 ? styles.is360 : ''
                } ${isQQ ? styles.Vivo : ''}`}>
                <div className={styles.contentTitleWrap}>
                    <span className={styles.contentTitle}>{content[0]?.title}</span>
                    <span className={styles.contentDate}>{content[0]?.date}</span>
                </div>
                <div className={styles.contentText}>{content[0]?.text}</div>
            </div>
        );
    }
}

export default errorBoundary(Acticle);
