import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';
import { handleDownLoad } from '../../handleDownload';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import { Browser } from '../../server/util';
class Picture extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };
    /* 初始化*/
    constructor(props) {
        super(props);
        this.state = {
            isSafari: false, // safari
            isQQ: false, // QQ
            isUc: false, // Uc
            isBaidu: false, // baidu
            isQuark: false, // Quark
            isOPPO: false, // OPPO
            is360: false,
            isWeChat: false, // wechat
        };
    }

    handleDownload = () => {
        handleDownLoad();
    };

    setClassName = () => {
        const browser = Browser();
        const isSafari = browser.isSafari;
        const isUc = browser.isUc;
        const isBaidu = browser.isBaidu;
        const isQQ = browser.isQQ;
        const isQuark = browser.isQuark;
        const isOPPO = browser.isOPPO;
        const is360 = browser.is360;
        const isWeChat = browser.isWeChat;

        this.setState({
            isSafari,
            isUc,
            isBaidu,
            isQQ,
            isQuark,
            isOPPO,
            is360,
            isWeChat,
        });
    };
    componentDidMount = () => {
        this.setClassName();
    };

    render() {
        const {
            isSafari, //
            isUc,
            isBaidu,
            isQQ,
            isQuark,
            isOPPO,
            is360,
            isWeChat,
        } = this.state;
        const { content } = this.props;

        return (
            <img
                className={`${styles.img} ${isSafari ? styles.Safari : ''} ${isUc ? styles.Uc : ''} ${
                    isBaidu ? styles.Baidu : ''
                }  ${isQQ ? styles.QQ : ''} ${isQuark ? styles.Quark : ''} ${isOPPO ? styles.OPPO : ''} ${
                    is360 ? styles.is360 : ''
                } ${isWeChat ? styles.WeChat : ''}`}
                src={formatImageWebpAuto(content[0]?.img)}
            />
        );
    }
}

export default errorBoundary(Picture);
