// 判断ios/Android
export const isIos = () => {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

    if (isiOS) {
        // alert('IOS');

        return true;
    } else {
        // alert('安卓');

        return false;
    }
};
// 获取ua
export const getUa = () => {
    const ua = navigator.userAgent.toLowerCase(); // 这里将所有字母转小写

    return ua;
};

export const isSafari = () => {
    // 判断是否为safari
    const ua = navigator.userAgent.toLowerCase();

    if (
        ua.indexOf('applewebkit') > -1 &&
        ua.indexOf('mobile') > -1 &&
        ua.indexOf('safari') > -1 &&
        ua.indexOf('linux') === -1 &&
        ua.indexOf('android') === -1 &&
        ua.indexOf('chrome') === -1 &&
        ua.indexOf('ios') === -1 &&
        ua.indexOf('browser') === -1
    ) {
        return true;
    } else {
        return false;
    }
};

// 360浏览器（极速内核）

const check360 = () => {
    const result = false;

    for (let key in navigator.plugins) {
        // np-mswmp.dll只在360浏览器下存在
        if (navigator.plugins[key].filename === 'internal-nacl-plugin') {
            return !result;
        }
    }

    return result;
};

export const is360 = () => {
    // 判断是否为safari
    const u = navigator.userAgent.toLowerCase();

    if (
        u.indexOf('UCBrowser') > -1 ||
        u.indexOf('MQQBrowser') > -1 ||
        u.indexOf('baiduboxapp') > -1 ||
        u.indexOf('Firefox') > -1 ||
        u.indexOf('VivoBrowser') > -1 ||
        u.indexOf('HeyTapBrowser') > -1 ||
        u.indexOf('Quark') > -1 ||
        u.indexOf('Edg') > -1 ||
        (isSafari() || check360())
    ) {
        return false;
    } else {
        return true;
    }
};

// 判断浏览器
export const Browser = () => {
    const u = navigator.userAgent;

    const option = {
        // 移动终端浏览器版本信息
        ua: u,
        isOppoClient: u.indexOf('PDPM00') > -1, // OPPO手机
        trident: u.indexOf('Trident') > -1, // IE内核
        presto: u.indexOf('Presto') > -1, // opera内核
        webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或uc浏览器
        isUc: u.indexOf('UCBrowser') !== -1,
        isQQ: u.indexOf('MQQBrowser') !== -1,
        isBaidu: u.indexOf('baiduboxapp') !== -1,
        isFirefox: u.indexOf('Firefox') > -1,
        isVivo: u.indexOf('VivoBrowser') > -1,
        isOPPO: u.indexOf('HeyTapBrowser') > -1,
        isQuark: u.indexOf('Quark') > -1,
        isEdg: u.indexOf('Edg') !== -1,
        isSafari: isSafari(),
        is360: is360(), // 360
        isWeChat: /MicroMessenger/i.test(window.navigator.userAgent),
        iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, // 是否iPad
        webApp: u.indexOf('Safari') === -1, // 是否web应该程序，没有头部与底部
    };

    return option;
};
